// import { Link } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router";
import ExpandMore from "@material-ui/icons/ExpandMore";
function Services({ headerItemClass, setHeaderItemClass, setShowMenu }) {
  const history = useHistory();
  const [showExtraPages, setShowExtraPages] = useState("");
  const [extraPagesMobile, setExtraPagesMobile] = useState(false);
  // const [windowSize, setWindowSize] = useState(window.innerWidth);
  // useEffect(() => {

  // }, [window.innerWidth]);
  return (
    <li
      onMouseOver={() => {
        setShowExtraPages("showen");
        setHeaderItemClass("services");
      }}
      onMouseOut={() => setShowExtraPages("")}
      className={`header_item ${headerItemClass} ${
        window.innerWidth <= 1030 && "menuPlanService"
      }`}
    >
      <span className="allSpan">
        <span onClick={() => setShowMenu(false)}>
          <a href="/#services">SERVICES</a>
        </span>
        {window.innerWidth <= 1030 && (
          <ExpandMore onClick={() => setExtraPagesMobile(!extraPagesMobile)} />
        )}
      </span>
      <ul
        onMouseOver={() => {
          setShowExtraPages("showen");
          setHeaderItemClass("services");
        }}
        onMouseOut={() => setShowExtraPages("")}
        className={`morePages ${showExtraPages} ${
          extraPagesMobile && "showforMobile"
        }`}
      >
        <li
          onClick={() => {
            history.push("/customer-care");
            setShowMenu(false);
          }}
        >
          Customer Care
        </li>
        <li
          onClick={() => {
            history.push("/trust-and-safety");
            setShowMenu(false);
          }}
        >
          Trust and Safety
        </li>
      </ul>
    </li>
  );
}

export default Services;
