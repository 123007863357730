import "./HeadLine.css";
import { motion } from "framer-motion";

function HeadLine({ headLine }) {
  return (
    <div className="headline_cont">
      <motion.h1
        animate={{
          x: 50,
          opacity: 1,
          transition: {
            duration: 1,
          },
        }}
        className="headline"
      >
        {headLine}
      </motion.h1>
    </div>
  );
}

export default HeadLine;
