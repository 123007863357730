import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// css file
import "./Header.css";
import logo from "../images/Flow-Logo.png";

import Services from "./Services";
import Menu from "@material-ui/icons/Menu";

function Header({ headerItems }) {
  const history = useHistory();
  const [headerScrolled, setHeaderScrolled] = useState("");
  const [headerItemClass, setHeaderItemClass] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const headerScroll = () => {
      if (window.pageYOffset > 250) {
        setHeaderScrolled("header_scrolled");
      } else {
        setHeaderScrolled("");
      }
    };
    window.addEventListener("scroll", headerScroll);
  }, []);
  return (
    <div className={`header ${headerScrolled}`}>
      <div className="logo">
        <Link className="logo_container" to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      {window.innerWidth <= 1030 && (
        <Menu className="menuBtn" onClick={() => setShowMenu(!showMenu)} />
      )}
      <ul className={`header_items ${showMenu && "show"}`}>
        {headerItems.map((item, index) => {
          if (item === "SERVICES") {
            return (
              <Services
                key={index}
                headerItemClass={headerItemClass}
                setHeaderItemClass={setHeaderItemClass}
                setShowMenu={setShowMenu}
              />
            );
          }
          return (
            <li
              onClick={() => {
                setShowMenu(false);
                history.push(`/${item.toLowerCase().replace(" ", "-")}`);
              }}
              onMouseOver={() => {
                setHeaderItemClass(item.toLowerCase().replace(" ", ""));
              }}
              className={`header_item ${headerItemClass}`}
              key={index}
            >
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Header;
