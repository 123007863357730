import TrendingFlat from "@material-ui/icons/TrendingFlat";
import { useHistory } from "react-router";
import YellowStripe from "../compontents/YellowStripe";
import "./Trust.css";
import { motion } from "framer-motion";
import cyberSecurity from "../images/cyber-security.jpg";
import { useEffect } from "react";
function Trust() {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="customerCare">
      <div className="fullCare">
        <div className="mainCare">
          <div>
            <span className="light_title">Enhance your community</span>
          </div>
          <div>
            <motion.h2
              animate={{
                y: -50,
                opacity: 1,
                transition: {
                  duration: 1,
                  delay: 0.5,
                },
              }}
            >
              Flowtechtics Builds Brand Protection
              <br /> Through Content Moderation
            </motion.h2>
          </div>
          <motion.p
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1.5,
                delay: 1,
              },
            }}
          >
            <span style={{ fontWeight: "bolder" }}>
              What would be the benefit to identify and support the true
              interest of real users on your platform or service?
            </span>
            <br />
            <br />
            Real user data counts. Removing malicious content, bots, and
            bullying creates a safe platform for your users. With them removed
            the real users remain. <br />
            We believe your customer deserves to be in a trustworthy and safe
            community. Unfortunately, the web is crawling with trolls,
            cyberbullies and bots that do not have the best interests of your
            community at heart. <br />
            Let us have them removed from your community
          </motion.p>
        </div>
        <div className="sideCare">
          <motion.div
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 2,
              },
            }}
          >
            <h4>
              <YellowStripe />
              A.I. & Human Moderation Entwined
            </h4>
            <p>
              A.I. software pre-filters the content preventing unnecessary load
              on the moderation team and they refine the content.
            </p>
          </motion.div>
          <motion.div
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 2.5,
              },
            }}
          >
            <h4>
              <YellowStripe />
              EMPLOYEE CARE
            </h4>
            <p>
              Our moderation team is supported by the mental and physical
              well-being team to handle the unusual exposure to heavy content of
              bullying, racism, nudity and other unwanted data.
            </p>
          </motion.div>
          <motion.div
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 3,
              },
            }}
          >
            <h4>
              <YellowStripe />
              RAPID RESPONSE
            </h4>
            <p>
              Because we work around the clock in a variety of languages, we are
              able to act swift and accurate to maintain a trustful and safe
              community
            </p>
          </motion.div>
        </div>
      </div>
      <motion.div
        animate={{
          x: 100,
          opacity: 1,
          transition: {
            duration: 1,
          },
        }}
        className="culture"
      >
        <div className="career_imgCont">
          <img src={cyberSecurity} alt="" />
        </div>
        <div className="career_cultureInfo">
          {/* <h2>Our Business Culture</h2> */}
          <p>
            Content moderation is essential to have a community operate, react
            and post content within your guidelines. This creates a feeling of
            trust and safety. <br />
            To make sure that your platform is free from offensive or abusive
            content, content moderation is essential as it protects your brand.
            The benefit of outsourcing content moderation is quite extensive of
            added value. <br />
            Through a specialized offshore team you are able to gain all of the
            benefits without diving into a pile of overpriced costs. Our team
            that provides this service has the needed experience and we provide
            the technical tools to integrate with your systems. The mental and
            physical support benefits of analyzing the response of the team to
            the content. Deploying such a team in-house or locally can be quite
            expensive in time and cost. <br />
            The option is to outsource. <br />
            Authentic, respectful with the support and trust that creates that
            genuine feeling of safety and security. We go more than the extra
            mile for our team so they can go the extra mile for you.
          </p>
        </div>
      </motion.div>
      <div className="offer">
        <div className="details">
          <motion.h4
            animate={{
              y: -50,
              transition: {
                duration: 1,
              },
            }}
          >
            <YellowStripe />
            Let’s Talk Trust & Safety
          </motion.h4>
          <motion.h2
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 0.5,
              },
            }}
          >
            Your community deserves to be trustful and safe
          </motion.h2>
          <p>
            Schedule a call with us so we can talk about moderating that
            unwanted malicious content from your platform
          </p>
        </div>
        <div className="goto">
          <motion.button
            animate={{
              x: 100,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 1,
              },
            }}
            onClick={() => history.push("/contact")}
          >
            Schedule a call <TrendingFlat />
          </motion.button>
        </div>
      </div>
    </div>
  );
}

export default Trust;
