import "./AboutUs.css";
import { motion } from "framer-motion";
import main from "../images/main.jpg";
import mission from "../images/mission.jpg";
import vision from "../images/vision.jpg";
import values from "../images/values.jpg";
import { useEffect } from "react";
function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="aboutUs">
      <div className="about">
        <motion.div
          animate={{
            y: -100,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 1,
            },
          }}
          className="about_info"
        >
          <p>
            We are Flowtechtics, A journey we started to be among the highest
            quality BPO companies that enhances consumer experience. We found
            the best centralized office location in Alexandria, Egypt. Purchased
            and developed to facilitate this path. The journey of Flowtechtics
            starts with serving solutions to our clients in the fields of
            Information Technology and Customer & Community Support.
          </p>
          <p>
            We do this through a simple authentic formula. Employee success
            creates customer success. <br />
            By providing continuous mentorship, training and development to our
            team. That creates the appropriate flow state mindset. <br />
            And that delivers the result of high quality we all deserve. As a
            new structured organization we enjoy the liberty of freedom of rigid
            policies and procedures to have organizational excellence. We are
            firm believers that this will lead to the best level of customer
            experience
          </p>
        </motion.div>
        <div className="about_imgCont">
          <motion.img
            animate={{
              x: 50,
              opacity: 1,
              transition: {
                duration: 1,
              },
            }}
            src={main}
            alt=""
          />
        </div>
      </div>
      {/* goals */}
      <div className="about_features">
        <motion.div
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1.5,
            },
          }}
          className="about_goal"
        >
          <img src={mission} alt="" />
          <p>
            To deliver excellence through our re-engineered quality assurance
            framework, privacy & security policies and procedures.
          </p>
        </motion.div>
        <motion.div
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1.5,
            },
          }}
          className="about_goal"
        >
          <img src={vision} alt="" />
          <p>
            Building the legendary place to work and delivering customer
            experience with an interactive digital presence where every client
            is satisfied with their interaction.
          </p>
        </motion.div>
        <motion.div
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1.5,
            },
          }}
          className="about_goal"
        >
          <img src={values} alt="" />
          <p>
            Authenticity Excellence through truth and honesty Respect All are
            treated equal and fair Support Together we enhance Trust Belief in
            the potential, executing the plan.
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default AboutUs;
