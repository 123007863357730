import TrendingFlat from "@material-ui/icons/TrendingFlat";
import { useHistory } from "react-router";
import YellowStripe from "../compontents/YellowStripe";
import "./CustomerCare.css";
import { motion } from "framer-motion";
import omnichannelCustomerExpectations from "../images/omnichannel-customer-expectations.jpg";
import { useEffect } from "react";
function CustomerCare() {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="customerCare">
      <div className="fullCare">
        <div className="mainCare">
          <div>
            <span className="light_title">EXTEND YOUR BUSINESS</span>
          </div>
          <div>
            <motion.h2
              animate={{
                y: -50,
                opacity: 1,
                transition: {
                  duration: 1,
                  delay: 0.5,
                },
              }}
            >
              Flowtechtics Builds Brand Loyalty Through
              <br /> Brilliant Customer Service
            </motion.h2>
          </div>
          <motion.p
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1.5,
                delay: 1,
              },
            }}
          >
            Our vision is to deliver the outstanding customer experience to
            cultivate customer loyalty. Customer support relies on two factors:{" "}
            <br />- Quality infrastructure capable of handling your call volume.{" "}
            <br />- Qualified professionals who can answer your customer’s
            inquiries. <br />
            We are driven to provide excellence on both of these fronts. That is
            why we have headhunted the high performing talent in the region.{" "}
            <br />
            From multinationals such as Amazon, IBM, Microsoft, Vodafone,
            Deutsche Telecom and AT&amp;T. Combined with dedicate line and our
            IP address we ensure the excellent service level you deserve.
            Whether the support is inbound, outbound, via voice, chat, mail, or
            any other channel.
            <br /> Omnichannel or Multichannel. We have the expertise to offer
            the enhanced customer experience that your brand deserves.
          </motion.p>
        </div>
        <div className="sideCare">
          <motion.div
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 2,
              },
            }}
          >
            <h4>
              <YellowStripe />
              RE-ENGINEERED FRAMEWORKS
            </h4>
            <p>
              We Engineer Our Policies, Procedures and Processes for the Highest
              Level of Quality, Privacy and Security Without Rigid Limitations.
            </p>
          </motion.div>
          <motion.div
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 2.5,
              },
            }}
          >
            <h4>
              <YellowStripe />
              EMPLOYEE SUCCESS
            </h4>
            <p>
              We focus on helping our employees feel at their best so they can
              offer the best service to your customers.{" "}
            </p>
          </motion.div>
          <motion.div
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 3,
              },
            }}
          >
            <h4>
              <YellowStripe />
              CLEAR OBJECTIVES
            </h4>
            <p>
              We operate in the triangle of action. Increasing customer
              satisfaction, reducing your operating costs and ensuring rapid
              availability of answering your customers inquiries.
            </p>
          </motion.div>
        </div>
      </div>
      <motion.div
        animate={{
          x: 100,
          opacity: 1,
          transition: {
            duration: 1,
          },
        }}
        className="culture"
      >
        <div className="career_imgCont">
          <img src={omnichannelCustomerExpectations} alt="" />
        </div>
        <div className="career_cultureInfo">
          <p>
            The BPO industry is plagued by poor staff retention. Many BPO firms
            must replace 30 - 60% of their workforce every year. Constant
            attrition causes higher staffing costs and a smaller pool of skilled
            employees. Flowtechtics is going against this trend. Our employees
            receive regular training to become highly skilled at customer
            engagement and satisfaction. Not just in soft skills and the
            knowledge base. We employ agents fluent in dozens of different
            languages so you can serve your global customers. Skilled customer
            service teams can be available all day, every day. Our priority is
            improving customer satisfaction by developing better customer
            service representatives. With omni-channel customer support and a
            driven team of specialists you can engage in the next level of
            customer experience. Customers will feel trusted with a dedicated
            team and the fact that in another channel their interaction is not
            lost in vain. This will provide a higher satisfaction and brand
            loyalty. The true win-win-win situation.
          </p>
        </div>
      </motion.div>
      <div className="offer">
        <div className="details">
          <motion.h4
            animate={{
              y: -50,
              transition: {
                duration: 1,
              },
            }}
          >
            <YellowStripe />
            Let’s Get in Touch
          </motion.h4>
          <motion.h2
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 0.5,
              },
            }}
          >
            Let's Talk About Customer Service
          </motion.h2>
          <p>
            CONTACT US. WE SHOW YOU HOW WE HAVE 24/7 AVAILABLE OMNI-CHANNEL
            SUPPORT WITH A HIGH QUALITY DRIVEN TEAM.
          </p>
        </div>
        <div className="goto">
          <motion.button
            animate={{
              x: 100,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 1,
              },
            }}
            onClick={() => history.push("/contact")}
          >
            Schedule a call with us <TrendingFlat />
          </motion.button>
        </div>
      </div>
    </div>
  );
}

export default CustomerCare;
