import { useEffect } from "react";
import { useHistory } from "react-router";
import { motion } from "framer-motion";
import callCenter from "../images/callCenter.jpg";
import safety from "../images/safety.png";
import customer from "../images/customer.png";
import "./Home.css";
function Home() {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home">
      <header className="home_header">
        <div className="custom-section-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1820"
            height="840"
            viewBox="0 0 481.54166 222.25"
            version="1.1"
            style={{
              backgroundImage: `url(${callCenter})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.75,
            }}
          ></svg>
          <motion.div
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
              },
            }}
            className="home_headerInfo"
          >
            <h1>
              We Enhance <br /> Customer Experience
            </h1>
            <p>
              We provide solutions for enhancing the customer experience. <br />
              By a re-engineered quality, security and privacy framework towards
              the community and customers of businesses. <br /> We execute this
              in combination of organizational excellence and employee success.
            </p>
          </motion.div>
        </div>
      </header>
      <div className="expertise">
        <div className="light_title">OUR EXPERTISE</div>
        <motion.h2
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 1,
            },
          }}
        >
          Customer Satisfaction Comes
          <br />
          From Corporate & Employee Excellence
        </motion.h2>
        <p>
          We strive to add value by a mercurial approach into the delivery of a
          better customer experience with every interaction. We are in a new era
          where customer care is top priority. The fixed factor is that
          customers expect to be heard and taken care of in a professional way.
          So should a corporation treat their team. We believe both are
          entwined. That is why we are convinced that with the best care
          possible of our team we can deliver the best care for your customers.
        </p>
      </div>
      <div id="services" className="cards">
        <motion.div
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1,
            },
          }}
          className="card"
        >
          <motion.img
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1.5,
              },
            }}
            src={customer}
            alt=""
          />
          <h4 onClick={() => history.push("/customer-care")}>Customer Care</h4>
          <p>
            We Provide Omnichannel customer care, inbound and outbound. Through
            interactions with voice, chat, mail and social media. This is
            brought to you by the top performers in customer service.
          </p>
        </motion.div>
        <motion.div
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1,
            },
          }}
          className="card"
        >
          <motion.img
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1.5,
              },
            }}
            src={safety}
            alt=""
          />
          <h4 onClick={() => history.push("/trust-and-safety")}>
            Trust and Safety
          </h4>
          <p>
            We Provide Content moderation services that ensures your community
            is trusted and safe. With moderation of text, voice, video and
            images. Through the combination of A.I. and human moderation.
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default Home;
