import ExpandLess from "@material-ui/icons/ExpandLess";
import { useEffect, useState } from "react";
import "./BtnToTop.css";
function BtnToTop() {
  const [showBtn, setShowBtn] = useState("");
  useEffect(() => {
    const showUpBtn = () => {
      if (window.pageYOffset > 250) {
        setShowBtn("showIt");
      } else {
        setShowBtn("");
      }
    };
    window.addEventListener("scroll", showUpBtn);
  }, []);

  return (
    <div
      onClick={() => window.scrollTo(0, 0)}
      className={`btntotop ${showBtn}`}
    >
      <ExpandLess />
    </div>
  );
}

export default BtnToTop;
