import "./Careers.css";
import YellowStripe from "../compontents/YellowStripe";
import TrendingFlat from "@material-ui/icons/TrendingFlat";
import { useHistory } from "react-router";
import { motion } from "framer-motion";
import OurBusinessCulture from "../images/OurBusinessCulture.jpg";
import { useEffect } from "react";
function Careers() {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="careersPage">
      <div className="expertise careers_expertise">
        <div className="light_title">BE PART OF OUR TEAM</div>
        <motion.h2
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1,
            },
          }}
        >
          Join us in the mission to build the best place to work.
        </motion.h2>
        <motion.p
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 0.5,
            },
          }}
        >
          We are building the best place to work in customer care and invite you
          to join us. We have redesigned our office, headhunted some of the best
          professionals and started the journey to be a people’s first BPO. With
          the initial focus on customer and community services. The team of
          achievers and action takers provide this higher value to our clients
          and their customers and you can be one of us.
        </motion.p>
      </div>
      <motion.div
        animate={{
          x: 100,
          opacity: 1,
          transition: {
            duration: 1,
          },
        }}
        className="culture"
      >
        <div className="career_imgCont">
          <img src={OurBusinessCulture} alt="" />
          <img className="career_topImg" src={OurBusinessCulture} alt="" />
        </div>
        <div className="career_cultureInfo">
          <h2>Our Business Culture</h2>
          <p>
            We are authentic, true about the words and actions that we say and
            do. Towards everyone involved in delivering our promise to deliver
            the best possible customer experience. <br />
            We work in a disciplined technology environment to have an abundance
            of opportunities. This makes a culture where you can expect the
            outcome of a certain action. We are able every next time to do it a
            bit better and a bit more. <br />
            We are respectful towards ourselves and our clients. Our opinion is
            only about ourselves what the rest does is not our business. But we
            respect them nevertheless. So we support support ourselves and each
            other, our clients, the community and their customers with a
            proactive attitude and an uplifting energy. This way we are building
            trust in the capability of our discipline and our team.
          </p>
        </div>
      </motion.div>
      <div className="offer">
        <div className="details">
          <motion.h4
            animate={{
              y: -50,
              transition: {
                duration: 1,
              },
            }}
          >
            <YellowStripe />
            Interested in an exciting career?
          </motion.h4>
          <motion.h2
            animate={{
              y: -50,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 0.5,
              },
            }}
          >
            Experienced with development or customer service? Send us your
            contact details so we can call you back
          </motion.h2>
        </div>
        <div className="goto">
          <motion.button
            animate={{
              x: 100,
              opacity: 1,
              transition: {
                duration: 1,
                delay: 1,
              },
            }}
            onClick={() => history.push("/contact")}
          >
            Let's get in touch <TrendingFlat />
          </motion.button>
        </div>
      </div>
    </div>
  );
}

export default Careers;
