import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import BtnToTop from "./compontents/BtnToTop";
import Footer from "./compontents/Footer";
import Header from "./compontents/Header";
import HeadLine from "./compontents/HeadLine";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import CustomerCare from "./pages/CustomerCare";
import Home from "./pages/Home";
import Trust from "./pages/Trust";

function App() {
  const [headerItems, setHeaderItems] = useState([
    "ABOUT US",
    "SERVICES",
    "CAREERS",
    "CONTACT",
  ]);
  useEffect(() => {
    setHeaderItems(["ABOUT US", "SERVICES", "CAREERS", "CONTACT"]);
  }, []);
  return (
    <Router>
      <div className="app">
        <BtnToTop />
        <Header headerItems={headerItems} />
        <Switch>
          <Route exact path="/about-us">
            <HeadLine headLine={headerItems[0]} />
            <AboutUs />
            {/* <Footer /> */}
          </Route>
          <Route exact path="/careers">
            {/* <Header headerItems={headerItems} /> */}
            <HeadLine headLine={headerItems[2]} />
            <Careers />
            {/* <Footer /> */}
          </Route>
          <Route exact path="/customer-care">
            {/* <Header headerItems={headerItems} /> */}
            <HeadLine headLine={"Customer Care"} />
            <CustomerCare />
            {/* <Footer /> */}
          </Route>
          <Route exact path="/trust-and-safety">
            {/* <Header headerItems={headerItems} /> */}
            <HeadLine headLine={"Trust and Safety"} />
            <Trust />
            {/* <Footer /> */}
          </Route>
          <Route exact path="/contact">
            {/* <Header headerItems={headerItems} /> */}
            <HeadLine headLine={headerItems[3]} />
            <Contact />
            {/* <Footer /> */}
          </Route>
          <Route exact path="/">
            {/* <Header headerItems={headerItems} /> */}
            <Home />
          </Route>
          <Route path="*">
            <h1
              style={{
                textAlign: "center",
                marginTop: "10vh",
                marginBottom: "29vh",
              }}
            >
              Page Not Available Go <Link to="/">Home</Link>
            </h1>
            <div
              style={{ position: "fixed", bottom: "0", width: "100%" }}
            ></div>
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
